import { Routes } from "@angular/router";
import { AuthGuard } from "../../../shared/authguards/auth.guard";

export const PAID_SICK_LEAVE_ROUTES: Routes = [
  {
    path: "paid-sick-leave",
    loadComponent: () => import("./paid-sick-leave.component")
      .then(m => m.PaidSickLeaveComponent),
    canActivate: [AuthGuard],
    data: { title: "pageTitles.paidSickLeave" },
    children: [
      {
        path: "service-info",
        loadComponent: () => import("./paid-sick-leave-info/paid-sick-leave-info.component")
          .then(m => m.PaidSickLeaveInfoComponent),
        data: { title: "pageTitles.paidSickLeaveInfo" },
      },
      {
        path: "payment-instructions-info",
        loadComponent: () => import("./../payment-instructions-info/payment-instructions-info.component")
          .then(m => m.PaymentInstructionsInfoComponent),
        data: { title: "pageTitles.paymentInstructionsInfo" },
      },
      {
        path: "feedback-info",
        loadComponent: () => import("./../feedback-info/feedback-info.component")
          .then(m => m.FeedbackInfoComponent),
        data: { title: "pageTitles.feedbackInfo" },
      },
      {
        path: "review",
        loadComponent: () => import("./paid-sick-leave-review/paid-sick-leave-review.component")
          .then(m => m.PaidSickLeaveReviewComponent),
        data: { title: "pageTitles.paidSickLeave" },
      },
    ],
  },
];
