import { Routes } from "@angular/router";
import { AuthGuard } from "../../../shared/authguards/auth.guard";

export const PAID_CONSULTATION_ROUTES: Routes = [
  {
    path: "paid-consultation",
    loadComponent: () => import("./paid-consultation.component").then(m => m.PaidConsultationComponent),
    canActivate: [AuthGuard],
    data: { title: "pageTitles.paidConsultation" },
    children: [
      {
        path: "",
        loadComponent: () => import("../../diseases/disease-info/disease-info.component").then(m => m.DiseaseInfoComponent),
        data: { title: "pageTitles.paidConsultation" },
      },
      {
        path: "service-info",
        loadComponent: () => import("./paid-consultation-info/paid-consultation-info.component")
          .then(m => m.PaidConsultationInfoComponent),
        data: { title: "pageTitles.paidConsultationInfo" },
      },
      {
        path: "payment-instructions-info",
        loadComponent: () => import("./../payment-instructions-info/payment-instructions-info.component")
          .then(m => m.PaymentInstructionsInfoComponent),
        data: { title: "pageTitles.paymentInstructionsInfo" },
      },
      {
        path: "feedback-info",
        loadComponent: () => import("./../feedback-info/feedback-info.component")
          .then(m => m.FeedbackInfoComponent),
        data: { title: "pageTitles.feedbackInfo" },
      },
      {
        path: "info",
        loadComponent: () => import("../../diseases/disease-header/disease-header.component").then(m => m.DiseaseHeaderComponent),
        data: { title: "pageTitles.diseaseInfo" },
      },
      {
        path: "review",
        loadComponent: () => import("../../diseases/disease-review/disease-review.component").then(m => m.DiseaseReviewComponent),
        data: { title: "pageTitles.diseasesReview" },
      },
      {
        path: "message",
        loadComponent: () => import("../../message/message.component").then(m => m.MessageComponent),
        data: { title: "pageTitles.diseasesMessages" },
      },
    ],
  },
];
