import { Routes } from "@angular/router";
import { AuthGuard } from "../../shared/authguards/auth.guard";
import { ExternalPatientRoleGuard } from "../../shared/authguards/external-patient-role.guard";

export const MAIN_ROUTES: Routes = [
  {
    path: "",
    loadComponent: () => import("./main.component").then(m => m.MainComponent),
    data: { title: "pageTitles.mainPage" },
    canActivate: [AuthGuard, ExternalPatientRoleGuard],
    children: [
      {
        path: "message",
        loadComponent: () => import("../message/message.component").then(m => m.MessageComponent),
        data: { title: "pageTitles.message" },
      },
    ],
  },
  {
    path: "paid-services-info",
    loadComponent: () => import("./paid-services-info/paid-services-info.component").then(m => m.PaidServicesInfoComponent),
    data: { title: "pageTitles.paidServicesInfo" },
  },
  {
    path: "ward-info",
    loadComponent: () => import("./ward-info/ward-info.component").then(m => m.WardInfoComponent),
    data: { title: "pageTitles.wardInfo" },
  },
];
