import { Routes } from "@angular/router";
import { AuthGuard } from "../../../shared/authguards/auth.guard";

export const PAID_PRESCRIPTION_ROUTES: Routes = [
  {
    path: "paid-prescriptions",
    loadComponent: () => import("./paid-prescriptions.component").then(m => m.PaidPrescriptionsComponent),
    canActivate: [AuthGuard],
    data: { title: "pageTitles.prescriptions" },
    children: [
      {
        path: "",
        loadComponent: () => import("../../prescriptions/prescription-list/prescription-list.component")
          .then(m => m.PrescriptionListComponent),
        data: { title: "pageTitles.prescriptions" },
      },
      {
        path: "service-info",
        loadComponent: () => import("./paid-prescriptions-info/paid-prescriptions-info.component")
          .then(m => m.PaidPrescriptionsInfoComponent),
        data: { title: "pageTitles.paidPrescriptionInfo" },
      },
      {
        path: "payment-instructions-info",
        loadComponent: () => import("./../payment-instructions-info/payment-instructions-info.component")
          .then(m => m.PaymentInstructionsInfoComponent),
        data: { title: "pageTitles.paymentInstructionsInfo" },
      },
      {
        path: "feedback-info",
        loadComponent: () => import("./../feedback-info/feedback-info.component")
          .then(m => m.FeedbackInfoComponent),
        data: { title: "pageTitles.feedbackInfo" },
      },
      {
        path: "new",
        loadComponent: () => import("../../prescriptions/new-prescription/new-prescription.component")
          .then(m => m.NewPrescriptionComponent),
        data: { title: "pageTitles.newPrescription" },
      },
      {
        path: "info",
        loadComponent: () => import("../../prescriptions/prescription-header/prescription-header.component")
          .then(m => m.PrescriptionHeaderComponent),
        data: { title: "pageTitles.prescriptionInfo" },
      },
      {
        path: "review",
        loadComponent: () => import("../../prescriptions/prescription-review/prescription-review.component")
          .then(m => m.PrescriptionReviewComponent),
        data: { title: "pageTitles.prescriptionReview" },
      },
      {
        path: "message",
        loadComponent: () => import("../../message/message.component")
          .then(m => m.MessageComponent),
        data: { title: "pageTitles.prescriptionMessages" },
      },
    ],
  },
];
