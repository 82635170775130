<ng-container *ngIf="isMobile; else desktop">
  <div *ngIf="mobileMenuOpen">
    <div class="black-screen"></div>
    <app-mobile-menu
      (clickOutside)="closeMenu()"
      (usedLink)="closeMenu()"
      [areNewMessages]="areNewMessages">
    </app-mobile-menu>
  </div>
  <div *ngIf="headerInfo.isBackground"
       class="wrapper">
    <div [ngClass]="{ onScroll: shrinkHeader, 'header-background-curved': !isMainMenuPage, 'header-background-not-curved': isMainMenuPage }"
         class="header-background ">
      <hr style="height: 0.8vh; visibility: hidden;" />
      <div class="container space-evenly">
        <button (click)="clickOnLeftIcon()"
                *ngIf="headerInfo.isLeftIcon, else noLeftIcon"
                [attr.aria-label]="headerInfo.leftIconName === 'Back' ?
                ('accessibility.back' | translate) : ('accessibility.goMainPage' | translate)"
                class="col-2 clear-button "
                routerLink="{{ headerInfo.leftIconRouterLink }}">
          <img [alt]="headerInfo.leftIconName === 'Back' ?
               ('accessibility.back' | translate) : ('accessibility.goMainPage' | translate)" class="{{ headerInfo.leftIconName }}"
               id="left-button"
               loading="lazy"
               src="assets/header-icons/{{ headerInfo.leftIconName }}.svg" />
        </button>

        <!--h1 tag sr-only added for mobile application accessibility score bypass reasons -->
        <h1 class="sr-only">{{ headerInfo.title | translate }}</h1>

        <button (click)="clickOnTitle()"
                class="col-8 clear-button"
                id="header-title"
                style="color: white">
          {{ headerInfo.title | translate | uppercase }}
        </button>

        <!-- menu button -->
        <button (click)="openMenu()"
                [attr.aria-label]="areNewMessages ? ('accessibility.openMenuNewMessage' | translate) : ('accessibility.openMenu' | translate)"
                class="col-2 clear-button me-3">
          <ng-container *ngIf="areNewMessages;then menuWithDot; else noNewMessages">

          </ng-container>
          <ng-template #menuWithDot>
            <img [alt]="'accessibility.openMenuNewMessage' | translate" class="menu"
                 id="right-button-dot"
                 loading="lazy"
                 src="assets/header-icons/Menu-dot.svg" />
          </ng-template>
          <ng-template #noNewMessages>
            <img [alt]="'accessibility.openMenu' | translate" class="menu"
                 id="right-button"
                 loading="lazy"
                 src="assets/header-icons/Menu.svg" />
          </ng-template>
        </button>

      </div>

      <div [ngClass]="{ hide: shrinkHeader }"
           class="container space-evenly extra-info"
           id="extra-info">
        <span *ngIf="headerInfo.isPatientNameDisplayed && ((isLoggedIn$ | async) || isNewUser)"
              id="patient-name">{{ patientName }}</span>
        <span *ngIf="headerInfo.isInfoText"
              id="info-text">{{ headerInfo.infoText | translate }}</span>
      </div>
    </div>
    <div *ngIf="showProgressBar && isLoggedIn$ | async">
      <div [ngClass]="{ 'progress-mot': shrinkHeader }"
           class="motivation-progress-bar">
        <ngb-progressbar [value]="overallProgressBarValue"
                         class="bar"
                         type="info">
        </ngb-progressbar>
      </div>
    </div>
    <div [ngClass]="{ 'header-padding-curved': !isMainMenuPage, 'header-padding-not-curved': isMainMenuPage }"></div>
  </div>
</ng-container>
<ng-template #desktop>
  <div *ngIf="headerInfo.isBackground"
       class="desktop-wrapper">
    <div [ngClass]="{ onScroll: shrinkHeader }"
         class="header-background py-3">
      <div class="d-flex flex-column">
        <div class="desktop-row-1 d-flex px-4">

          <div class="col-3 d-flex align-items-center desktop-buttons">
            <button (click)="clickOnLeftIcon()"
                    *ngIf="headerInfo.isLeftIcon && headerInfo.isLeftIconDesktop, else noLeftIcon"
                    [attr.aria-label]="'accessibility.goMainPage' | translate"
                    class="h-100 clear-button desktop-home-button"
                    id="home"
                    routerLink="/">
              <img [alt]="'accessibility.goMainPage' | translate" loading="lazy"
                   src="assets/header-icons/Home-outline.svg" />
            </button>
            <ng-container *ngIf="isLoggedIn$ | async">
              <button (click)="clickOnLeftIcon()"
                      [attr.aria-label]="'accessibility.goSettingsPage' | translate "
                      class="h-100 clear-button desktop-settings-button"
                      id="account"
                      routerLink="/settings">
                <img [alt]="'accessibility.goSettingsPage' | translate" loading="lazy"
                     src="assets/header-icons/Account.svg">
              </button>

              <ng-container *ngIf="areNewMessages; then dotEnvelope; else noNewMessages"></ng-container>
              <ng-template #dotEnvelope>
                <button (click)="clickOnMessagesIcon()"
                        [attr.aria-label]="'accessibility.goMessagePageNewMessage' | translate"
                        class="h-100 clear-button desktop-messages-button">
                  <img [alt]="'accessibility.goMessagePageNewMessage' | translate" loading="lazy"
                       src="assets/header-icons/Messages-dot.svg" />
                </button>
              </ng-template>
              <ng-template #noNewMessages>
                <button (click)="clickOnMessagesIcon()"
                        [attr.aria-label]="'accessibility.goMessagePage' | translate "
                        class="h-100 clear-button desktop-no-messages-button">
                  <img [alt]="'accessibility.goMessagePage' | translate" loading="lazy"
                       src="assets/header-icons/Messages.svg" />
                </button>
              </ng-template>
            </ng-container>
          </div>

          <h1 class="col-6 desktop-title"
              id="header-title">
            {{ headerInfo.title  | translate | uppercase }}
          </h1>

          <div class="col-3 d-flex justify-content-end">

            <div class="lang-menu">
              <button (click)="useLanguage('ee')"
                      [class.active]="currentLanguage === 'ee'"
                      class="clear-button" lang="ee">EST
              </button>
              <button (click)="useLanguage('en')"
                      [class.active]="currentLanguage === 'en'"
                      class="clear-button" lang="en">ENG
              </button>
              <button (click)="useLanguage('ru')"
                      [class.active]="currentLanguage === 'ru'"
                      class="clear-button" lang="ru">RUS
              </button>
            </div>

            <div id="log-out">
              <button (click)="onLogout()"
                      *ngIf="(isLoggedIn$ | async); else menuIcon"
                      class="logOut-text clear-button">
                {{ "settings.logOut" | translate | uppercase }}
              </button>
              <ng-template #menuIcon>
                <img (keydown.enter)="accessibility.handleKeyDown('welcome/menu')" [alt]="'accessibility.generalInformationIcon' | translate"
                     class="Menu-desktop"
                     loading="lazy"
                     routerLink="welcome/menu"
                     src="assets/header-icons/Menu.svg" />
              </ng-template>
            </div>
          </div>
        </div>

        <div [ngClass]="{ hide: shrinkHeader }"
             class="container space-evenly extra-info"
             id="extra-info">
          <span *ngIf="headerInfo.isPatientNameDisplayed && ((isLoggedIn$ | async) || isNewUser)"
                id="patient-name">{{ patientName }}</span>
          <span *ngIf="headerInfo.isInfoText"
                id="info-text">{{
              headerInfo.infoText | translate
            }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="showProgressBar && isLoggedIn$ | async">
      <div [ngClass]="{ 'progress-mot': shrinkHeader }"
           class="motivation-progress-bar">
        <ngb-progressbar [value]="overallProgressBarValue"
                         class="bar"
                         type="info">
        </ngb-progressbar>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noLeftIcon>
  <div class="col-2"
       id="pseudo-item-1"></div>
</ng-template>
